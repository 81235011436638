import React from 'react'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { wineType } from '@/model/wine'
import { isEcommerceState } from '@/recoil/isEcommerce'
import LocalizedGatsbyLink from '@/components/LocalizedGatsbyLink'

import {
  Container,
  List,
  Item,
  AdditionalItem
} from './style'

const Nav = ({ onItemClick = () => {}, activeIndex, items }) => {
  const handleFinderClick = () => {
    // console.log('click')
  }

  const isEcommerce = useRecoilValue(isEcommerceState)

  const handleClick = (index) => {
    onItemClick(index)
  }

  const handleKeyPress = (index) => {
    if (event.key == 'Enter') {
      onItemClick(index)
    }
  }

  return (
    <Container>
      <List>
        {
          items.map((item, index) => {
            return (
              <Item
                role='button'
                tabIndex={ 0 }
                active={ index === activeIndex }
                key={ index }
                index={ index }
                onClick={ () => handleClick(index) }
                onKeyDown={() => handleKeyPress(index)}
              >
                { item.name }
              </Item>
            )
          })
        }
        {isEcommerce && (
          <AdditionalItem index={ items.length + 1 } active>
            <LocalizedGatsbyLink to='/other-products'>Other products</LocalizedGatsbyLink>
          </AdditionalItem>
        )}
        {/* <AdditionalItem index={ items.length + 2 } active onClick={ (e) => handleFinderClick(e) }>
          Wine finder
        </AdditionalItem> */}
      </List>
    </Container>
  )
}

Nav.propTypes = {
  onItemClick: PropTypes.func,
  activeIndex: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(
    wineType
  ).isRequired
}

export default Nav
